<template>
  <!-- 产品中心 -->
  <div class="center">
    <img
      style="width: 100%; height: auto;"
      src="@/assets/images/product-center.png"
      alt="产品中心"
    />

    <!-- tabs -->
    <div class="tab-box">
      <MyTabs
        class="tab"
        :tabIndex="tabIndex"
        :tabs="tabsList"
        @changeTab="changeTab"
      />
    </div>

    <!-- AI 智慧城市 -->
    <keep-alive>
      <AISmartCity v-if="activeTab === 'AI智慧城市'" />

      <!-- 城市互联网 -->
      <UrbanInternet v-else-if="activeTab === '城市互联网'" />

      <!-- 更多产品 -->
      <MoreProducts v-else-if="activeTab === '更多产品'" />
    </keep-alive>

    <!-- 版权信息 -->
    <Copyright />
  </div>
</template>

<script>
import MyTabs from '@/components/MyTabs'
import AISmartCity from './components/AISmartCity.vue'
import UrbanInternet from './components/UrbanInternet.vue'
import MoreProducts from './components/MoreProducts.vue'
import Copyright from '@/components/Copyright'

export default {
  name: 'center',
  components: {
    MyTabs,
    AISmartCity,
    UrbanInternet,
    MoreProducts,
    Copyright
  },

  data() {
    return {
      tabIndex: 0,
      activeTab: 'AI智慧城市',
      // tabs 列表
      tabsList: [
        {
          name: '产品详细介绍',
          img: require('@/assets/images/blue-smart-city.png'),
          activeImg: require('@/assets/images/smart-city.png')
        }
        // {
        //   name: '城市互联网',
        //   img: require('@/assets/images/blue-city-internet.png'),
        //   activeImg: require('@/assets/images/city-internet.png')
        // },
        // {
        //   name: '更多产品',
        //   img: require('@/assets/images/blue-more-products.png'),
        //   activeImg: require('@/assets/images/more-products.png')
        // }
      ]
    }
  },

  beforeMount() {
    // console.log(this.$route.params)
    // 设置 tab active
    const index = this.$route.params.index
    if (index) {
      this.tabIndex = Number(index) - 1
      this.$nextTick(() => {
        this.activeTab = ['AI智慧城市', '城市互联网', '更多产品'][this.tabIndex]
      })
    }
  },

  methods: {
    // 切换 tab
    changeTab(name) {
      this.activeTab = name
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-box {
  position: relative;
  margin-bottom: 140px;

  .tab {
    bottom: -60px;
  }
}
</style>
