<template>
  <!-- 城市互联网 -->
  <div>
    <!-- 娱乐 start -->
    <div class="entertainment container-w1140">
      <div class="entertainment-left">
        <ParagraphTitle
          title="娱乐"
          linePosition="line-left"
          textDirection="text-left"
        />
        <p class="entertainment-text">
          在“互联网+”时代，娱乐向着碎片化、智能化、情感化发展，领域也更加丰富，
          中天云网作为互联网先行者，率先将时下新星技术与娱乐内容联系起来，
          形成了一条“娱乐生态链”，为用户提供更丰富内容、更精准的个性化娱乐服务，
          为企业带来最佳的商业模式，共同建立完整的娱乐生态圈。
        </p>
        <div>
          <span class="entertainment-example">游戏</span>
          <span class="entertainment-example">小说</span>
          <span class="entertainment-example">泛娱乐</span>
          <span class="entertainment-example" style="margin-right: 0"
            >视频</span
          >
        </div>
      </div>
      <div>
        <img
          style="vertical-align: middle;"
          src="@/assets/images/entertainment.png"
          alt="娱乐"
        />
      </div>
    </div>
    <!-- 娱乐 end -->

    <!-- 社交 start -->
    <div class="social-contact review" style="margin-bottom: 131px">
      <SocialContact
        :img="socialContactImg"
        :infoText="SocialContactText"
        :example="['交友', '新媒体', '直播', '短视频']"
      >
        <ParagraphTitle title="社交" linePosition="line-left" />
      </SocialContact>
    </div>
    <!-- 社交 end -->

    <!-- 电商 start -->
    <div class="e-commerce entertainment container-w1140">
      <div>
        <img
          style="vertical-align: middle;"
          src="@/assets/images/e-commerce.png"
          alt="电商"
        />
      </div>
      <div class="entertainment-left">
        <ParagraphTitle
          title="电商"
          linePosition="line-left"
          textDirection="text-right"
        />
        <p class="entertainment-text e-commerce-text">
          在国家“互联网+”行动计划的实施标志着电子商务成为战略性新兴产业，
          中天云网依托强大的互联网数据资源优势，整合多方信息资源，
          实现线上线下紧密结合的高效管理，以完善的产品和服务满足顾客个性化、
          多元化的购物体验，推进电子商务创新应用，完善电子商务支撑服务体系，
          促进了电子商务进入一个全新的发展阶段。
        </p>
        <div style="text-align: right">
          <span class="entertainment-example">新零售</span>
          <span class="entertainment-example">社团</span>
          <span class="entertainment-example" style="margin-right: 0"
            >打折网</span
          >
        </div>
      </div>
    </div>
    <!-- 电商 end -->

    <!-- 平台 start -->
    <div class="social-contact review" style="margin-bottom: 96px">
      <SocialContact
        :img="platformImg"
        :infoText="platformText"
        :example="['云计算', '资质', '广告', '企业服务', '便民服务', '支付']"
      >
        <ParagraphTitle title="平台" linePosition="line-left" />
      </SocialContact>
    </div>
    <!-- 平台 end -->

    <!-- 定制研发 start -->
    <div class="entertainment container-w1140">
      <div class="entertainment-left">
        <ParagraphTitle
          title="定制研发"
          linePosition="line-left"
          textDirection="text-left"
        />
        <p class="entertainment-text">
          受全球化和信息化的驱动，产业融合加速，新产业新模式新业态不断催生，
          中天云网结合时下新兴技术，将硬件、软件完成深度捆绑融合，
          完成多种业务价值链所共有的部分进行优化整合，提供一体化高质量服务，
          提升自身在产业中的地位。
        </p>
        <div>
          <span class="entertainment-example">游戏定制研发</span>
          <span class="entertainment-example" style="margin-right: 0"
            >软件定制研发</span
          >
        </div>
      </div>
      <div>
        <img
          style="vertical-align: middle;"
          src="@/assets/images/dingzhiyanfa.png"
          alt="定制研发"
        />
      </div>
    </div>
    <!-- 定制研发 end -->

    <!-- 视频审核 start -->
    <div class="review">
      <SocialContact
        :img="reviewImg"
        :infoText="reviewText"
        :example="['视频审核']"
      >
        <ParagraphTitle title="视频审核" linePosition="line-left" />
      </SocialContact>
    </div>
    <!-- 视频审核 end -->
  </div>
</template>

<script>
import ParagraphTitle from '@/components/ParagraphTitle'
import SocialContact from '@/components/SocialContact'

export default {
  name: 'UrbanInternet',
  components: {
    ParagraphTitle,
    SocialContact
  },

  data() {
    return {
      // 社交
      socialContactImg: require('@/assets/images/social-contact.png'),
      SocialContactText: `随着通信技术的突飞猛进，我国移动互联网行业也获得了长足的发展，
          社交模式在悄无声息中也发生了翻天覆地的变化，中天云网利用多年互联
          网技术沉淀打造互联网+社交新模式可以为用户提供持续不断的价值，
          与用户建立更深层次的情感连接，成功实现崭新的形态与特质，加速网络全球化时代进程，
          使每个自然人个体都能成功聚合为新的社会群体。`,

      //   平台
      platformImg: require('@/assets/images/platform.png'),
      platformText: `随中天云网通过专业的技术团队、丰富的市场经验，先进的生产装置、
      可靠的管理团队、提供产、学、研全方位一体化的 开发、测试、运营及售后的一站式解决方案，
      有效帮助企业降低技术门槛、减少研发成本、提升开发效率的同时并实现研究开发到科技成果产业化。`,

      //   视频审核
      reviewImg: require('@/assets/images/shipinshenghe.png'),
      reviewText: `视频审核是利用大数据分析的内容过滤平台的流程和关键技术，
      音频过滤与图片过滤系统两者的结合，一方面利用自动截屏技术，抓取视频关键帧，
      通过图片过滤系统实现信息过滤；一方面结合音频，综合分析判断；成为人工审核的替代工具和重要辅助手段。`
    }
  }
}
</script>

<style lang="scss" scoped>
// 娱乐
.entertainment {
  margin: 0 auto 56px;
  display: flex;
  justify-content: space-between;
  color: #333;

  .entertainment-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 570px;
    // padding: 0 10px;

    .entertainment-text {
      margin-top: 43px;
      line-height: 32px;
      color: #333;
      margin-bottom: 19px;
    }
  }
}

.entertainment-example {
  display: inline-block;
  width: 124px;
  height: 50px;
  border: 1px solid #666;
  margin-right: 22px;
  font-size: 18px;
  color: #333;
  line-height: 50px;
  text-align: center;
}

.social-contact ::v-deep .title-name {
  color: #fff;
}
.social-contact ::v-deep .line-left::after {
  border-color: #fff;
}

// 电商
.e-commerce-text {
  text-align: right;
}

// 视频审核
.review ::v-deep .example {
  color: #fff;
  border-color: #fff;
}
</style>
