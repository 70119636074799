<template>
  <!-- 文章 标题 -->
  <div class="artivle">
    <div class="artivle-box">
      <h2 class="artivle-num">{{ num }}</h2>
    </div>
    <h2 class="artivle-title">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'ArticleTitle',
  props: {
    num: String,
    title: String
  }
}
</script>

<style lang="scss" scoped>
.artivle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artivle-box {
  display: flex;
  justify-content: center;
  width: 280px;
  position: relative;

  &::after {
    display: inline-block;
    content: '';
    width: 100%;
    height: 0;
    border-bottom: 1px solid #333;
    position: absolute;
    bottom: 50%;
    transform: translateY(-1px);
    z-index: -1;
  }

  .artivle-num {
    margin: 0;
    width: 78px;
    font-size: 60px;
    font-weight: bold;
    color: #333;
    background-color: #fff;
    text-align: center;
  }
}

.artivle-title {
  text-align: center;
  color: #333;
  font-size: 26px;
}
</style>
