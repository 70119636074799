<template>
  <!-- AI 智慧城市 -->
  <div class="programme">
    <!-- <ParagraphTitle title="解决方案" />
    <p class="scheme-description container-w1140">
      中天云网按照“大整合、大平台、大数据、大协同”的理念，
      创新应用数字孪生、物联网、大数据和5G等技术，打造全生态新型AI智慧城市，
      加强智慧基础网络设施建设、围绕1+3+N的原则，即建立1个城市大脑（运营中心）、
      建成1网统管+1脸通办+1数融产的原则，同时建设N个新型智慧应用。建设“1+3+N”结构的新型智慧城市。
    </p> -->

    <!-- 中天云网城市大脑 -->
    <!-- <div class="brain container-w1140">
      <div class="brain-img">
        <img v-imgLazy="cityBrain" alt="中天云网城市大脑" />
      </div>
      <div class="article">
        <ArticleTitle num="1" title="中天云网城市大脑" />
        <p class="article-text">
          城市智脑集成了地理信息、GPS数据、建筑物三维数据、统计数据、
          摄像头采集画面等多类数据，可以把市政、警务、消防、交通、通讯、
          商业等各部门各类型的数据融合打通，汇集在统一的大数据平台上。
          打破部门间的信息孤岛，统筹完成城市基础设施规划并作为城市可持续发展的决策依据。
        </p>
      </div>
    </div> -->

    <!-- 1网统管+1脸通办+1数融产 -->
    <!-- <div class="brain container-w1140">
      <div class="article">
        <ArticleTitle num="3" title="1网统管+1脸通办+1数融产" />
        <p class="article-text">
          进一步深化“互联网+政务服务”标准化和信息化建设，推进城市治理现代化，构建互联网+监管平台，
          实现“一网统管”“一脸通办”“一数融产”三大汇聚管理，切实破解政务服务事项标准不一致、平台功能不完善、
          数据共享不充分、办事服务不便捷等问题，推动政务服务精准化、便捷化和移动化。
        </p>
      </div>
      <div class="brain-img">
        <img v-imgLazy="cityCenter" alt="中天云网城市大脑" />
      </div>
    </div> -->

    <!-- N个新型智慧应用 -->
    <div class="new-app">
      <ArticleTitle num="N" title="详细产品介绍" />
      <!-- <p class="article-text" style="margin-top: 27px;">
        以城市需求和个性化建设为目的，提供菜单式可选的N个智慧应用产品。
      </p> -->
      <div class="new-smart-img">
        <img v-imgLazy="newSmartApp" alt="中天云网城市大脑" />
      </div>
    </div>

    <!-- 核心技术优势 -->
    <!-- <ParagraphTitle title="核心技术优势" />
    <p class="scheme-description container-w1140">
      为客户相关智慧城市项目提供本地化产品研发定制、工程实施、运营维护等服务，
      协助填补人才与技术不足，应对各种风险，交付卓越成果，并紧跟变革步伐。
    </p> -->

    <!-- <div class="advantage container-w1200">
      <div
        class="advantage-item"
        v-for="item in advantageList"
        :key="item.name"
      >
        <img v-imgLazy="item.img" alt="" />
        <span class="advantage-name">{{ item.name }}</span>
        <span style="color: #999">
          <span>{{ item.describe1 }}</span>
          <span style="margin: 0 10px;">{{ item.describe2 }}</span>
          <span>{{ item.describe3 }}</span>
        </span>
      </div>
    </div> -->

    <!-- AI 赋能 -->
    <!-- <ParagraphTitle title="AI赋能" />
    <p class="scheme-description container-w1140" style="text-align:center;">
      中天云网新型AI智慧城市支撑城市全场景的智能化决策和自主演进
    </p>
    <div class="ai-mpowerment">
      <div
        class="mpowerment-box"
        v-for="item in AIEmpowerment"
        :key="item.title"
      >
        <img v-imgLazy="item.img" alt="" />
        <span class="mpowerment-title">{{ item.title }}</span>
        <div class="mpowerment-describe">
          <h4 class="describe-title">{{ item.title }}</h4>
          <p class="describe-text">{{ item.describe }}</p>
        </div>
      </div>
    </div> -->

    <!-- 自主研发-软件产品 -->
    <div class="new-app">
    <ParagraphTitle title="产品详细介绍" /></div>
    <!-- <p class="scheme-description container-w1140" style="text-align:center;">
      中天云网已研发N个应用及数据中台，集成多个信息化系统，并可根据需求定制。
    </p> -->
    <div class="new-smart-img">
        <img v-imgLazy="newSmartApp" alt="中天云网城市大脑"   width="1200px" />
      </div>
     <!-- <div class="software-product container-w1140">
      <div class="softwart" v-for="item in softwareList" :key="item.text1">
        <span style="margin-bottom: 10px;" v-if="item.text1">{{
          item.text1
        }}</span>
        <span>{{ item.text2 }}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import ParagraphTitle from '@/components/ParagraphTitle'
import ArticleTitle from '@/components/ArticleTitle'

export default {
  name: 'AISmartCity',
  components: {
    ParagraphTitle,
    ArticleTitle
  },

  data() {
    return {
      cityBrain: require('@/assets/images/city-brain.png'),
      cityCenter: require('@/assets/images/center-3.png'),
      newSmartApp: require('@/assets/images/new-smart-app.png'),
      // 技术优势
      advantageList: [
        {
          img: require('@/assets/images/ai.png'),
          name: 'AI人工智能',
          describe1: '跨媒体AI',
          describe2: '鲁棒AI',
          describe3: '深度学习'
        },
        {
          img: require('@/assets/images/big-data.png'),
          name: '大数据',
          describe1: '大媒体检索',
          describe2: '大数据分析',
          describe3: '大数据挖掘'
        },
        {
          img: require('@/assets/images/vision.png'),
          name: '计算机数据',
          describe1: '多模态识别',
          describe2: '行为分析',
          describe3: '内容理解'
        },
        {
          img: require('@/assets/images/VR-AR.png'),
          name: 'VR/AR',
          describe1: '人机交互',
          describe2: '3D建模技术',
          describe3: '虚拟人技术'
        }
      ],

      // AI 赋能
      AIEmpowerment: [
        {
          img: require('@/assets/images/ai-production.png'),
          title: 'AI生产力公共服务平台',
          describe: `为地方政府提供产业数字化转型的核心AI技术，
          打造地方产业数字化转型的AI生产力公共服务平台，赋能企
          业数字化转型的AI技术和服务。`
        },
        {
          img: require('@/assets/images/ai-industry-brain.png'),
          title: 'AI行业大脑',
          describe: `利用平台服务能力以AI技术为核心，集成大数据、
          工业传感、5G等技术，构建垂直行业的AI行业大脑，在地方打
          造特色产业数字化转型的行业标杆。`
        },
        {
          img: require('@/assets/images/ai-application.png'),
          title: 'AI行业应用',
          describe: `深入理解行业场景，基于人工智能操作系统，
          结合行业知识图谱快速输出行业算法模型和应用，为产业
          数字化转型各行业AI赋能。`
        }
      ],

      // 自主研发-软件产品
      softwareList: [
        {
          text1: '中天云网城市大脑',
          text2: '综合管理平台'
        },
        {
          text1: '中天云网大数据',
          text2: 'AI中台'
        },
        {
          text1: '中天云网电子政务',
          text2: '信息综合管理平台 '
        },
        {
          text1: '中天云网智慧乡村',
          text2: '管理平台'
        },
        {
          text1: '中天云网智慧社区',
          text2: '管理平台'
        },
        {
          text1: '中天云网智慧旅游综合',
          text2: '管理服务平台'
        },
        {
          text1: '中天云网垃圾分类',
          text2: '管理系统'
        },
        {
          text1: '中天云网智慧交通',
          text2: '管理平台'
        },
        {
          text1: '中天云网智慧医疗',
          text2: '管理系统'
        },
        {
          text1: '',
          text2: '......'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// .programme {
//   // text-align: center;
// }

.scheme-description {
  margin: 43px auto 71px;
  color: #666;
  line-height: 32px;
  // text-align: center;
}

.brain {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 130px;

  .brain-img {
    width: 471px;
    height: 295px;
  }

  .article {
    width: 516px;
    padding: 0 18px;
  }
}

.article-text {
  margin-top: 45px;
  color: #666;
  line-height: 32px;
}

.new-app {
  display:none;
  text-align: center;
}

.new-smart-img {
  width: 1200px;
  height: autopx;
  margin: auto;
}

.advantage {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 78px;

  .advantage-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .advantage-name {
    margin: 17px 0 23px;
    font-size: 22px;
    color: #333;
  }
}

.ai-mpowerment {
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}

.mpowerment-box {
  position: relative;
  width: 280px;
  height: 200px;
  margin: 0 27px;

  .mpowerment-title {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 100%;
    height: 200px;
    line-height: 200px;
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background: url('../../../assets/images/ai-connect.png') center no-repeat;

    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: rgba(57, 121, 255, 0.4);
      left: 1px;
      top: 1px;
    }
  }

  .mpowerment-describe {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: #3979ff;
    top: 1px;
    left: 1px;
    transition: all 0.3s;

    .describe-title {
      font-size: 20px;
      color: #fff;
      margin: 21px 0;
      text-align: center;
    }

    .describe-text {
      margin: 0 14px 14px;
      font-size: 14px;
      color: #fff;
      line-height: 32px;
    }
  }

  &:hover .mpowerment-describe {
    visibility: visible;
    opacity: 1;
  }
}

.software-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 35px;

  .softwart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 176px;
    height: 71px;
    border: 1px solid #666;
    margin: 12px 10px;
    font-size: 14px;
    color: #333;
    transition: all 0.3s;

    &:hover {
      background-color: #3979ff;
      color: #fff;
      border-color: #3979ff;
    }
  }
}
</style>
