<template>
  <!-- 更多产品 -->
  <div>
    <!-- 建筑 start -->
    <div class="entertainment container-w1140">
      <div class="entertainment-left">
        <ParagraphTitle
          title="建筑"
          linePosition="line-left"
          textDirection="text-left"
        />
        <p class="entertainment-text">
          建筑产业互联网作为信息化技术最好的体现，成功将数据与现实世界连接在一起，
          基于中天云网统一的标准化数据平台，虚拟的三维模型和在模型上的集成数据可以进行工程的设计、
          建造和运营维护，实现参与方数据被连通，达到使用数据来解决实际问题的目的。
        </p>
        <div>
          <span class="entertainment-example">土建</span>
          <span class="entertainment-example">水利</span>
          <span class="entertainment-example">市政建设</span>
          <span class="entertainment-example">工程勘察设计</span>
          <span class="entertainment-example" style="margin-right: 0"
            >工程勘察设计</span
          >
        </div>
      </div>
      <div>
        <img
          style="vertical-align: middle;"
          src="@/assets/images/jianzhu.png"
          alt="建筑"
        />
      </div>
    </div>
    <!-- 建筑 end -->

    <!-- 制造 start -->
    <div class="manufacture">
      <SocialContact
        :img="manufactureImg"
        :infoText="manufactureText"
        :example="['可回收再生资源', '静脉产业园', '垃圾分类回收']"
      >
        <ParagraphTitle title="制造" linePosition="line-left" />
      </SocialContact>
    </div>
    <!-- 制造 end -->

    <!-- 运输 start -->
    <div class="transport entertainment container-w1140">
      <div>
        <img
          style="vertical-align: middle;"
          src="@/assets/images/yunshu.png"
          alt="运输"
        />
      </div>
      <div class="entertainment-left">
        <ParagraphTitle
          title="运输"
          linePosition="line-left"
          textDirection="text-right"
        />
        <p class="entertainment-text" style="text-align: right;">
          中天云网互联网+运输云计算平台基于“SaaS平台+移动App”管理模式，
          作为进阶深度服务运输的全链条运力平台，专注于运输链条的顶端切入，
          构建一个社区型的互联运输协同网络，高效率的完成信息协同管理、
          标准化作业流程，成功实现降本增效，以及整个运输生态链的效率提升，体
          验更佳。
        </p>
        <div style="text-align: right">
          <span class="entertainment-example" style="margin-right: 0"
            >道路货物运输</span
          >
        </div>
      </div>
    </div>
    <!-- 运输 end -->

    <!-- 销售 start -->
    <div class="manufacture sale">
      <SocialContact
        :img="saleImg"
        :infoText="saleText"
        :example="[
          '销售机械设备',
          '安全技术防范产品',
          '通讯设备',
          '建筑材料',
          '装饰材料',
          '电子产品'
        ]"
      >
        <ParagraphTitle title="销售" linePosition="line-left" />
      </SocialContact>
    </div>
    <!-- 销售 end -->
  </div>
</template>

<script>
import ParagraphTitle from '@/components/ParagraphTitle'
import SocialContact from '@/components/SocialContact'

export default {
  name: 'MoreProducts',
  components: {
    ParagraphTitle,
    SocialContact
  },

  data() {
    return {
      //   制造
      manufactureImg: require('@/assets/images/zhizao.png'),
      manufactureText: `
      随着科技的高速发展以及互联网时代的到来，中国制造向中国智造演进，中天云网作为科技强国中坚力量，
      率先推动信息化(IT)与自动化(OT)深度融合，提供基于物联网的智能制造解决方案，用智慧为制造业赋能，
      助力中国制造数字化转型，提升客户体验以及提高效率和质量。
      `,

      //   销售
      saleImg: require('@/assets/images/xiaoshou.png'),
      saleText: `
      随着互联网+发展的深入，互联网交易的优势逐渐涌现，品牌互联网化势在必行，
      中天云网拥有强大的实力、专业的团队、科学的体系、周到的服务，
      将着力全面提升产品和服务质量，打造“商业+科技”互联网帝国，开启跨界共赢新模式，助力中国发展迈入质量时代！
      `
    }
  }
}
</script>

<style lang="scss" scoped>
// 娱乐
.entertainment {
  margin: 0 auto 56px;
  display: flex;
  justify-content: space-between;
  color: #333;

  .entertainment-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 570px;
    // padding: 0 10px;

    .entertainment-text {
      margin-top: 43px;
      line-height: 32px;
      color: #333;
      margin-bottom: 19px;
    }
  }
}

.entertainment-example {
  display: inline-block;
  width: 124px;
  height: 50px;
  border: 1px solid #666;
  margin-right: 22px;
  margin-bottom: 19px;
  font-size: 18px;
  color: #333;
  line-height: 50px;
  text-align: center;
}

.manufacture ::v-deep .title-name {
  color: #fff;
}
.manufacture ::v-deep .line-left::after {
  border-color: #fff;
}
.manufacture ::v-deep .example {
  color: #fff;
  border-color: #fff;
}

.transport {
  margin-top: 101px;
  margin-bottom: 101px;
}
</style>
