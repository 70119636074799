var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"entertainment container-w1140"},[_c('div',{staticClass:"entertainment-left"},[_c('ParagraphTitle',{attrs:{"title":"建筑","linePosition":"line-left","textDirection":"text-left"}}),_c('p',{staticClass:"entertainment-text"},[_vm._v(" 建筑产业互联网作为信息化技术最好的体现，成功将数据与现实世界连接在一起， 基于中天云网统一的标准化数据平台，虚拟的三维模型和在模型上的集成数据可以进行工程的设计、 建造和运营维护，实现参与方数据被连通，达到使用数据来解决实际问题的目的。 ")]),_vm._m(0)],1),_vm._m(1)]),_c('div',{staticClass:"manufacture"},[_c('SocialContact',{attrs:{"img":_vm.manufactureImg,"infoText":_vm.manufactureText,"example":['可回收再生资源', '静脉产业园', '垃圾分类回收']}},[_c('ParagraphTitle',{attrs:{"title":"制造","linePosition":"line-left"}})],1)],1),_c('div',{staticClass:"transport entertainment container-w1140"},[_vm._m(2),_c('div',{staticClass:"entertainment-left"},[_c('ParagraphTitle',{attrs:{"title":"运输","linePosition":"line-left","textDirection":"text-right"}}),_c('p',{staticClass:"entertainment-text",staticStyle:{"text-align":"right"}},[_vm._v(" 中天云网互联网+运输云计算平台基于“SaaS平台+移动App”管理模式， 作为进阶深度服务运输的全链条运力平台，专注于运输链条的顶端切入， 构建一个社区型的互联运输协同网络，高效率的完成信息协同管理、 标准化作业流程，成功实现降本增效，以及整个运输生态链的效率提升，体 验更佳。 ")]),_vm._m(3)],1)]),_c('div',{staticClass:"manufacture sale"},[_c('SocialContact',{attrs:{"img":_vm.saleImg,"infoText":_vm.saleText,"example":[
        '销售机械设备',
        '安全技术防范产品',
        '通讯设备',
        '建筑材料',
        '装饰材料',
        '电子产品'
      ]}},[_c('ParagraphTitle',{attrs:{"title":"销售","linePosition":"line-left"}})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"entertainment-example"},[_vm._v("土建")]),_c('span',{staticClass:"entertainment-example"},[_vm._v("水利")]),_c('span',{staticClass:"entertainment-example"},[_vm._v("市政建设")]),_c('span',{staticClass:"entertainment-example"},[_vm._v("工程勘察设计")]),_c('span',{staticClass:"entertainment-example",staticStyle:{"margin-right":"0"}},[_vm._v("工程勘察设计")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require("@/assets/images/jianzhu.png"),"alt":"建筑"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require("@/assets/images/yunshu.png"),"alt":"运输"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"right"}},[_c('span',{staticClass:"entertainment-example",staticStyle:{"margin-right":"0"}},[_vm._v("道路货物运输")])])}]

export { render, staticRenderFns }