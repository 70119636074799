<template>
  <div class="social-contact">
    <img
      style="width: 100%; height: 505px; object-fit: cover;"
      v-imgLazy="img"
      alt=""
    />
    <div class="social-contact-info">
      <slot></slot>
      <p class="info-text">
        {{ infoText }}
      </p>
      <div class="">
        <span class="example" v-for="item in example" :key="item">{{
          item
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialContact',
  props: {
    infoText: String, // 文字描述
    img: String, // 背景图
    example: Array // 示例
  }
}
</script>

<style lang="scss" scoped>
.social-contact {
  position: relative;
  // height: 505px;
  width: 100%;
  object-fit: cover;
  //   margin-bottom: 131px;

  .social-contact-info {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;

    .info-text {
      width: 1182px;
      margin-top: 38px;
      margin-bottom: 35px;
      text-align: center;
      line-height: 32px;
    }
  }
}

.example {
  display: inline-block;
  min-width: 124px;
  height: 50px;
  border: 1px solid #666;
  margin-right: 22px;
  font-size: 18px;
  color: #333;
  line-height: 50px;
  text-align: center;
  padding: 0 19px;
  box-sizing: border-box;
}
</style>
