<template>
  <!-- 自定义 tabs -->
  <div class="my-tabs">
    <div
      class="tabs-item"
      :class="{ active: item.name === active }"
      v-for="item in tabs"
      :key="item.name"
      @click="setActive(item.name)"
    >
      <div v-if="item.img" style="margin-bottom: 18px;">
        <img :src="item.name === active ? item.activeImg : item.img" />
      </div>
      <span class="tabs-name">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyTabs',
  props: {
    tabs: Array,
    tabIndex: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      active: this.tabs[this.tabIndex].name
    }
  },
  // mounted() {
  //   console.log(this.tabIndex)
  // },

  methods: {
    // 切换 tab
    setActive(name) {
      console.log(name)
      this.active = name
      this.$emit('changeTab', name)
    }
  }
}
</script>

<style lang="scss" scoped>
.my-tabs {
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
  // width: 0px;
  min-height: 60px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 21px rgba(1, 35, 97, 0.08);
  display: flex;
  justify-content: space-between;
  padding: 19px 0;
  transition: all 1s;

  .tabs-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 300px;
    cursor: pointer;
  }

  .tabs-name {
    font-size: 24px;
  }

  .active {
    color: #fff;

    &::before {
      position: absolute;
      bottom: -19px;
      z-index: -1;
      content: '';
      width: 100%;
      height: calc(100% + 38px);
      border-radius: 10px;
      background-color: #08467c;;
    }
  }
}
</style>
